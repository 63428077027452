<!--
* @Description:学习中心->我的试卷
-->
<template>
  <div class="trainingWrap container">
    <header>
      <el-row class="hedaer-Bg" type="flex">
        <el-col :span="12">
          <div class="grid-content header-left-content">
            <div class="logo-wrap">
              <img src="../../assets/image/homeImg/logo.png" />
            </div>
            <div class="app-title" @click="$router.push('/home')">
              河南省团干部网络教育平台
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="grid-content header-right-content">
            <div @click="pageJumpLogin" v-if="userName == ''">登录</div>
            <div v-else class="userName-wrap">
              <el-dropdown placement="bottom">
                <span class="el-dropdown-link">
                  学员{{ userName }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="$router.push('/accountSafe')">账号安全</el-dropdown-item>
                  <el-dropdown-item @click.native="$router.push('/login')">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="nav_side">
        <nav>
          <router-link active-class="active" to="/home">首页</router-link>
          <router-link active-class="active" to="/trainingClass"
            >培训班次</router-link
          >
          <router-link active-class="active" to="/studyCenter"
            >学习中心</router-link
          >
          <router-link active-class="active" to="/examCenter"
            >考试中心</router-link
          >
          <router-link active-class="active" to="/newcomerPost"
            >新人驿站</router-link
          >
          <router-link active-class="active" to="/liveRoom"
            >直播大厅</router-link
          >
          <router-link active-class="active" to="/boutiqueCourse"
            >精品课程</router-link
          >
          <router-link active-class="active" to="/trainDynamic"
            >培训动态</router-link
          >
          <router-link active-class="active" to="/helpCenter"
            >帮助中心</router-link
          >
        </nav>
      </div>
    </header>
    <div class="main-content">
      <div class="navLocation">
        学习中心>我的试卷
      </div>
      <div class='workContentWrap'>
         <div class="titleWrap"><div class="title">课程</div></div>
        <div class="workContent">
            <div class="contentRight">
            <ul class='homeListbox'>
                <li class='homeItem'  v-for="(item,index) in examData" @click='jumpExamItemPage(item,index)' :key='index'>
                  <div class='titleWrap  lightItem'>
                    <div class="homeName">{{item.courseTitle}}</div>
                  </div>
                  <ul class='examListWrap'>
                    <li v-for="(examItem,examIndex) in item.examList" :key='examIndex'>
                      <div>{{examItem.paperTitle}}</div>
                      <div  class='showIcon isok'>{{examItem.paperScore}}</div>
                    </li>
                  </ul>
                </li>
            </ul>
            </div>
        </div>
      </div>
    </div>
    <footer-com></footer-com>
  </div>
</template>

<script>
import footerCom from '@/components/footer.vue'
export default {
  name: '',
  components: {
    footerCom
  },
  props: {},
  watch: {},
  data () {
    return {
      userName: '',
      examData: [],
      activeIndex1: 0,
      activeIndex2: 0,
      chooseIndex: 0,
      homeListDate: [
        {examId: 1,
          examName: '全省新任职团干部培训班',
          time: '2022年4月21日'},
        {examId: 2,
          examName: '全省新任职团干部培训班2',
          time: '2022年4月21日'},
      ],
      examListDate: [
        {examId: 1,
          examName: '全省新任职团干部培训班',
          time: '2022年4月21日'},
        {examId: 2,
          examName: '全省新任职团干部培训班2',
          time: '2022年4月21日'},
      ]
    }
  },
  methods: {
    pageJumpLogin () {
      this.$router.push('/login')
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    getqueryStudentCourseWithExam () {
      let reqOption = {
        'pageNo': 1,
        'pageSize': 999,
      }
      console.log(this.$apiUrl.queryCoursePage)
      this.$axios.post(this.$apiUrl.queryStudentCourseWithExamPage, reqOption).then(res => {
        console.log(res)
        let {records} = res.data.data
        this.examData = JSON.parse(JSON.stringify(records))
        // this.recordsData = [...JSON.parse(JSON.stringify(records)), ...JSON.parse(JSON.stringify(records)), ...JSON.parse(JSON.stringify(records)), ...JSON.parse(JSON.stringify(records)), ...JSON.parse(JSON.stringify(records))]
      })
    },
    // 获取local中用户详细信息
    getUserInfo () {
      let userinfo = localStorage.getItem('studentInfo')
        ? JSON.parse(localStorage.getItem('studentInfo'))
        : ''
      console.log(userinfo)
      if (userinfo) {
        let { stuName } = userinfo
        this.userName = stuName
      }
    },
    jumpExamItemPage (item, index) {
      console.log(item.examId)
      this.chooseIndex = index
    }
  },
  mounted () {
    this.getqueryStudentCourseWithExam()
    this.getUserInfo()
  },
}
</script>

<style scoped lang="scss">
.container {
  position: relative;
  min-height: 100vh;
  background-color: #fbfbff;

  header {
    background: url("../../assets/image/homeImg/headerBg.png");
    background-size: 100% 100%;
    height: 146px;
  }

  .hedaer-Bg {
    /* background-color:#6a8dea; */
    background-size: 100% 100%;
    height: 42px;
    width: 1240px;
    margin: 0 auto;
    line-height: 56px;
    /* padding: 0 100px; */

    .header-left-content {
      font-size: 22px;
      color: white;
      font-weight: 800;
      display: flex;
      justify-content: start;

      .app-title {
        cursor: pointer;
      }

      .logo-wrap {
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 42px;
        }
      }
    }

    .header-right-content {
      font-size: 18px;
      color: white;
      height: 42px;
      display: flex;
      justify-content: end;
      align-items: center;

      .userName-wrap {
        height: 34px;
        line-height: 34px;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      ::v-deep .el-dropdown {
        font-size: 18px;
        color: #4774df;
        background-color: #fff;
        height: 34px;
        line-height: 34px;
        border-radius: 5px 5px 5px 5px;
        opacity: 1;
        padding: 0px 10px;
      }
    }
  }

  .nav_side {
    width: 1240px;
    margin: 0 auto;
    height: 61px;
    line-height: 61px;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 22px;
      font-weight: bold;
    }

    nav a {
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.8);
      line-height: 61px;
    }

    nav a:hover {
      opacity: 1;
    }

    .active {
      font-size: 26px;
      font-weight: bold;
      color: #ffffff;
      opacity: 1;
      border-bottom: 3px solid #ffffff;
    }
  }
}
  .main-content {
    width: 1240px;
    padding-bottom: 188px;
    margin:  0 auto 0;
    padding: 30px;
    padding-bottom: 188px;
    background-color: transparent;
    .navLocation{
      margin-bottom: 30px;
      font-size: 22px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #000000;
    }
    .workContentWrap{
        padding: 0px 32px;
        background: #FFFFFF;
        box-shadow: 0px 6px 30px 1px rgba(0,0,0,0.12);
        border-radius: 7px 7px 7px 7px;
        opacity: 1;
            .titleWrap{
                height: 59px;
                margin-bottom: 20px;
                border-bottom: 1px solid rgba(112,112,112,0.2);
                .title{
                  width: 50px;
                height: 58px;
                margin-left: 20px;
                font-size: 22px;
                font-family: Microsoft YaHei-Regular, Microsoft YaHei;
                font-weight: 400;
                color: #1C4ECC;
                line-height: 54px;
                border-bottom: 2px solid #1C4ECC;
                }
            }
    }
    .workContent{
    margin-bottom: 100px;
    .contentRight{
      min-height: 568px;
      background: #FFFFFF;
      opacity: 1;
      .title{
        color: #000000;
      }
      .topTip{
        height: 48px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #000000;
        line-height: 48px;
        padding-left: 30px;
      }
      .homeItem{
          font-size: 18px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #000000;
        .titleWrap{
          padding: 20px 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .examListWrap{
          >li{
            padding: 20px 30px;
            display: flex;
            justify-content: space-between;
            color: rgba(0,0,0,0.6);

          }
        }
        .showIcon{
            width: 150px;
            text-align: center;
           font-size: 18px;
           font-size: 18px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
        }
        .isok{
            color: #FF6F00
          }
      }
      .lightItem{
        background-color: #F3F7FF
      }
    }
    }
  }
</style>
